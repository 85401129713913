import { useEffect } from "react";
import { useLocation } from "@remix-run/react";
import { analytics } from "../app/services/segment.client";
import { type Session } from "./session";
import {
  ElementLocation,
  ElementType,
  EventName,
  SearchItemProperties,
  SearchType,
  SKUProperites,
} from "../app/services/segment-types";

export function useSegmentIdentify(user: Session, jobTitle?: string) {
  const { sub, email } = user;
  useEffect(() => {
    if (window.ENV.CI) {
      return;
    }

    analytics
      .identify(sub, {
        email,
        jobTitle,
      })
      .catch((error) =>
        console.error("[Segment Client][identify] Error:", error),
      );
  }, [email, sub, jobTitle]);
}

export function useSegmentPage() {
  const { pathname } = useLocation();
  useEffect(() => {
    if (window.ENV.CI) {
      return;
    }

    analytics
      .page(pathname)
      .catch((error) => console.error("[Segment Client][page] Error:", error));
  }, [pathname]);
}

export function segmentTrack(
  eventName: string,
  properties: Record<string, unknown> = {},
) {
  if (window.ENV.CI) {
    return;
  }

  analytics
    .track(eventName, properties)
    .catch((error) => console.error("[Segment Client][track] Error:", error));
}

export function segmentTrackForm(
  formName: string,
  properties: Record<string, unknown> = {},
) {
  if (window.ENV.CI) {
    return;
  }

  segmentTrack(EventName.FormSubmitted, {
    form_name: formName,
    form_data: properties,
  });
}

export function segmentTrackElement(
  eventName: EventName,
  text: string,
  type: ElementType,
  location: ElementLocation | string,
) {
  segmentTrack(eventName, {
    element_text: text,
    element_type: type,
    element_location: location,
  });
}

export function segmentTrackClick(
  text: string,
  type: ElementType,
  location: ElementLocation | string = document.title ?? "",
) {
  segmentTrackElement(EventName.ElementClicked, text, type, location);
}

function getTextContent(target: HTMLElement, parseChildNodes = false) {
  return parseChildNodes
    ? Array.from(target.childNodes)
        .map((node) => node.textContent)
        .filter(Boolean)
        .join(" - ")
    : (target.textContent?.trim() ?? "");
}

export function segmentTrackLinkClick(
  target: EventTarget,
  location: ElementLocation | string = document.title ?? "",
  parseChildNodes = false,
) {
  const text = getTextContent(target as HTMLLinkElement, parseChildNodes);
  segmentTrackClick(text, ElementType.Link, location);
}

export function segmentTrackButtonClick(
  target: EventTarget,
  location: ElementLocation | string = document.title ?? "",
  parseChildNodes = false,
) {
  const text = getTextContent(target as HTMLButtonElement, parseChildNodes);
  segmentTrackClick(text, ElementType.Button, location);
}

export function segmentTrackCreateSKU(properties: SKUProperites) {
  segmentTrack(EventName.SKUCreated, {
    entity_id: properties.entityId,
    entity_name: properties.entityName,
    entity_type: properties.entityType,
    sku_name: properties.skuName,
  });
}
export function segmentTrackUpdateSKU(properties: SKUProperites) {
  segmentTrack(EventName.SKUUpdated, {
    entity_id: properties.entityId,
    entity_name: properties.entityName,
    entity_type: properties.entityType,
    sku_id: properties.skuId,
    sku_name: properties.skuName,
  });
}
export function segmentTrackDeleteSKU(properties: SKUProperites) {
  segmentTrack(EventName.SKUDeleted, {
    entity_id: properties.entityId,
    entity_name: properties.entityName,
    entity_type: properties.entityType,
    sku_id: properties.skuId,
    sku_name: properties.skuName,
  });
}

export function segmentTrackSearchFocused(type: SearchType) {
  segmentTrack(EventName.SearchFocused, {
    search_type: type,
  });
}
export function segmentTrackSearchStarted(type: SearchType) {
  segmentTrack(EventName.SearchStarted, {
    search_type: type,
  });
}
export function segmentTrackSearchItemSelected(
  type: SearchType,
  properties: SearchItemProperties,
) {
  const data: Record<string, unknown> = {
    selection_position: properties.selectionPosition,
    selection_type: properties.selectionType,
  };

  if ("contractId" in properties) {
    data.contract_id = properties.contractId;
    data.contract_name = properties.contractName;
  }

  if ("entityId" in properties) {
    data.entity_id = properties.entityId;
    data.entity_name = properties.entityName;
  }

  if ("documentId" in properties) {
    data.document_id = properties.documentId;
  }

  if ("category" in properties) {
    data.category = properties.category;
  }

  segmentTrack(EventName.SearchItemSelected, {
    search_type: type,
    ...data,
  });
}
